import React from 'react'

import { Security, VerifiedUser, Visibility } from '@mui/icons-material'
import { Container, Grid, Paper, Stack, Typography } from '@mui/material'

import { Subtitle, Title } from 'shared/components'

type ValueCardProps = {
  title: string
  body: string
  icon: React.ReactNode
}

const ValueCard = ({ title, body, icon }: ValueCardProps) => (
  <Grid
    item
    xs={12}
    md={4}
  >
    <Paper
      variant='outlined'
      sx={{
        px: 2,
        py: 4,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        boxShadow: 8,
        border: 4,
        borderColor: 'primary.main',
      }}
    >
      {icon}
      <Stack
        direction='column'
        spacing={1}
      >
        <Typography
          variant='h5'
          component='h3'
          fontWeight={500}
        >
          {title}
        </Typography>
        <Typography>
          {body}
        </Typography>
      </Stack>
    </Paper>
  </Grid>
)

const TeamValues = () => (
  <Container
    maxWidth='lg'
    sx={{ pt: 6, pb: 8 }}
  >
    <Title
      colorful
      textAlign='center'
      pb={2}
    >
      Pilares más fuertes que el blockchain
    </Title>
    <Subtitle
      textAlign='center'
      fontWeight={300}
      pb={3}
    >
      Estamos comprometidos con la transparencia, la seguridad y una excelente experiencia de usuario.
    </Subtitle>
    <Grid
      container
      rowSpacing={3}
      columnSpacing={6}
      justifyContent='center'
      px={1}
    >
      <ValueCard
        title='Transparencia'
        body='Comprometidos con la claridad y la honestidad en todas nuestras operaciones.'
        icon={<Visibility  fontSize='large' />}
      />
      <ValueCard
        title='Seguridad'
        body='Priorizamos la seguridad de tus activos digitales con tecnología de punta.'
        icon={<Security  fontSize='large' />}
      />
      <ValueCard
        title='Experiencia de Usuario'
        body='Diseñamos cada interacción pensando en la simplicidad y la eficiencia.'
        icon={<VerifiedUser fontSize='large' />}
      />
    </Grid>
  </Container>
)

export default TeamValues
