import React from 'react'

import { Mail } from '@mui/icons-material'
import { Button, Container, Paper, Typography } from '@mui/material'

import { Title } from 'shared/components'

const TeamContact = () => (
  <Container
    maxWidth='lg'
    sx={{ pt: 6, pb: 8, px: 3 }}
  >
    <Paper
      variant='outlined'
      sx={{
        p: 3,
        mx: 'auto',
        width: '100%',
        maxWidth: 'md',
        textAlign: 'center',
        border: 4,
        borderColor: 'primary.main',
        boxShadow: 8,
      }}
    >
      <Title gutterBottom>
        ¿Quieres trabajar con nosotros?
      </Title>
      <Typography gutterBottom>
        Si estás interesado en formar parte de nuestro equipo, envíanos un correo a:
      </Typography>
      <Button
        target='_blank'
        rel='noopener noreferrer'
        href='mailto:satoshi@sugarblock.io'
        startIcon={<Mail />}
        variant='outlined'
        size='large'
        sx={{ mt: 2 }}
      >
        satoshi@sugarblock.io
      </Button>
    </Paper>
  </Container>
)

export default TeamContact
