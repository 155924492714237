import React from 'react'

import { SeoHeaders } from 'shared/components'

import TeamCarousel from '../components/team/carousel'
import TeamContact from '../components/team/contact'
import TeamTitle from '../components/team/title'
import TeamValues from '../components/team/values'
import Layout from '../layouts/landing'

const Team = () => (
  <Layout>
    <SeoHeaders
      title='¿Quiénes somos?'
      description='Conoce al equipo detrás de Sugarblock'
    />
    <TeamTitle />
    <TeamValues />
    <TeamCarousel />
    <TeamContact />
  </Layout>
)

export default Team
