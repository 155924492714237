import React from 'react'

import { Box } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { SplitSection, Subtitle, Title } from 'shared/components'

const TitleImage = () => (
  <Box
    sx={{
      overflow: 'hidden',
      borderRadius: 1,
      boxShadow: 8,
      border: 4,
      borderColor: 'primary.main',
    }}
  >
    <StaticImage
      src='../../images/team/team.png'
      alt='Equipo Sugarblock'
      loading='eager'
      placeholder='none'
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
  </Box>
)

const TeamTitle = () => (
  <SplitSection
    maxWidth='lg'
    minHeight='min(85vh, 600px)'
    imageComponent={<TitleImage />}
  >
    <Title
      mainTitle
      colorful
      pb={2}
    >
      Creemos en bitcoin y las criptomonedas
    </Title>
    <Subtitle
      mainTitle
      fontWeight={300}
    >
      En Sugarblock creamos la primera cuenta de ahorros en criptomonedas.
      Un producto innovadoramente simple que tiene la misión de acercar a las 600 millones
      de personas de Latinoamérica a la nueva era financiera.
    </Subtitle>
  </SplitSection>
)

export default TeamTitle
