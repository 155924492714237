import React from 'react'

import { Container } from '@mui/material'

import { Carousel, Subtitle, Title } from 'shared/components'

import teamMembers from '../../data/team_members'

const TeamCarousel = () => (
  <Container
    maxWidth='lg'
    sx={(theme) => ({
      pb: 8,
      [theme.breakpoints.up('md')]: {
        pt: 4,
      },
    })}
  >
    <Title
      colorful
      pb={2}
      textAlign='center'
    >
      El equipo
    </Title>
    <Subtitle
      textAlign='center'
      fontWeight={300}
      pb={5}
    >
      Anímate y descubre a los cerebros detrás de Sugarblock.
      Te invitamos a conocer a los miembros de nuestro equipo.
    </Subtitle>
    <Carousel data={teamMembers} />
  </Container>
)

export default TeamCarousel
